import { useEffect, useState } from 'react'
import { animated, useTransition } from '@react-spring/web'

import { Button, H4 } from 'design-system'
import FormCheckbox from 'components/Form/FormCheckbox'
import FormTextField from 'components/Form/FormTextField'
import { Modal, ModalProps, useModal } from 'components/ModalKit'

import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'

import { autoCompleteOff } from 'utils/autoCompleteOff'
import { useGeolocation } from 'hooks/useGeolocation'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useRouter } from 'next/router'
import { CSS } from '@stitches/react'
import { styled } from 'styles/stitches.config'

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xl',
})

export type FormInputs = {
  curb: boolean
  open: boolean
  term: string
}

const StoresSearchModal = ({
  modalId,
  onSubmitPath,
  transitionStyles,
}: {
  onModalSubmit?: () => void
  onSubmitPath: string
} & Partial<ModalProps>) => {
  const { closeModal } = useModal()
  const [open, setOpen] = useState(true)

  const transitions = useTransition(open, {
    from: {
      transform: 'translateY(100%)',
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(100%)',
    },
  })

  const checkBoxStyles: CSS = {
    justifyContent: 'space-between',
  }

  const { getGeolocation, geolocationIsGood, latitude, longitude } =
    useGeolocation()

  const [locationServices] = useLocalStorage(
    LocalStorageKeys.LOCATION_SERVICES,
    'false'
  )

  useEffect(() => {
    if (locationServices === 'true' && !geolocationIsGood) {
      getGeolocation()
    }
  }, [geolocationIsGood, getGeolocation, locationServices])

  const router = useRouter()
  const { query } = router

  const methods = useForm({
    defaultValues: {
      curb: query.curb === 'true',
      open: query.open === 'true',
      term: (query?.term && decodeURIComponent(query.term as string)) || '',
    },
  })

  const handleClose = () => {
    closeModal(modalId)
    setOpen(false)
  }

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    if (geolocationIsGood) {
      handleClose()

      if (data.term.toLowerCase() === 'demo') {
        const demoStoreId = '3c25205b-462a-4d80-93d6-145d99b8d7f3'

        router.push(`/explore/stores/${demoStoreId}`)
        return
      }

      router.push({
        pathname: onSubmitPath,
        query: {
          curb: data.curb,
          lat: latitude,
          lon: longitude,
          open: data.open,
          term: encodeURIComponent(data.term),
        },
      })
    }
  }

  return (
    <Modal.Root modalId={modalId as string} transitionStyles={transitionStyles}>
      <Modal.Overlay handleClose={handleClose} />
      <Modal.Content hAlign="center" vAlign="bottom">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            data-testid="search-form"
          >
            {transitions(
              (styles, item) =>
                item && (
                  <animated.div style={styles}>
                    <Modal.Card>
                      <Modal.Header>Search</Modal.Header>
                      <Modal.Main
                        css={{ fontSize: '$body1', lineHeight: '$body1' }}
                      >
                        <FormTextField
                          aria-label="Search Term"
                          autoComplete={autoCompleteOff()}
                          data-testid="stores-search-term"
                          name="term"
                          placeholder="Search for shop name, city, etc."
                        />

                        <H4 css={{ fontWeight: '$bold', marginBottom: '$m' }}>
                          Filters
                        </H4>
                        <Container>
                          <FormCheckbox
                            label="Accepting orders"
                            name="open"
                            reversed
                            css={checkBoxStyles}
                          />

                          <FormCheckbox
                            label="Curbside pickup"
                            name="curb"
                            reversed
                            css={checkBoxStyles}
                          />
                        </Container>
                      </Modal.Main>
                      <Modal.Footer>
                        <Button
                          cta
                          size="large"
                          type="submit"
                          data-testid="search-button"
                        >
                          Search
                        </Button>
                      </Modal.Footer>
                      <Modal.CloseButton handleClose={handleClose} />
                    </Modal.Card>
                  </animated.div>
                )
            )}
          </form>
        </FormProvider>
      </Modal.Content>
    </Modal.Root>
  )
}

export default StoresSearchModal
