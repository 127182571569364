import { InputHTMLAttributes } from 'react'
import { useFormContext, RegisterOptions } from 'react-hook-form'

/* Design System */
import { Input, Label, ErrorMessage } from 'design-system'
import { styled } from 'styles/stitches.config'

const Wrapper = styled('div', {
  marginBlock: '$s',
})

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id'> {
  alignRight?: boolean
  label?: string
  name: string
  type?: string
  options?: RegisterOptions
}

const FormTextField = ({
  label,
  name,
  type = 'text',
  options,
  ...props
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const error = errors[name]?.message

  return (
    <Wrapper>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input id={name} type={type} {...register(name, options)} {...props} />
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  )
}

export default FormTextField
