export function autoCompleteOff() {
  /* istanbul ignore next */
  if (typeof window !== 'undefined') {
    // because Chrome ignores autocomplete="off"
    if (navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc')) {
      return 'chrome-off'
    }
  }

  return 'off'
}
