import { InputHTMLAttributes } from 'react'
import { useFormContext, RegisterOptions } from 'react-hook-form'

import { styled } from 'styles/stitches.config'
import * as Icon from 'components/DesignSystemIcons'
import { Label as DefaultLabel } from 'design-system'

import type { CSS } from '@stitches/react'

const CHECK_MARK_RATIO = 4 / 7

const Label = styled(DefaultLabel, {
  display: 'flex',
  fontSize: '$body1',
  fontWeight: '$normal',
  lineHeight: '1.6',
  gap: '$xs',
  alignItems: 'center',
  letterSpacing: 'normal',

  variants: {
    reversed: {
      true: {
        flexDirection: 'row-reverse',
      },
    },
  },
})

const CheckMarkContainer = styled('div', {
  position: 'relative',
  height: '24px',
  width: '24px',
  flexShrink: 0,
})

const Aura = styled('span', {
  position: 'absolute',
  inset: 0,
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$white',
})

const Checkbox = styled('input', {
  appearance: 'none',
  borderRadius: '$xt',
  inset: 0,
  position: 'absolute',
  border: '1px solid $grey1000',
  boxSizing: 'border-box',

  '&:focus': {
    borderColor: '$grey1000',
  },

  [`&:checked + ${Aura}`]: {
    pointerEvents: 'none',
    display: 'inline-flex',
  },
  variants: {
    variant: {
      primary: {
        '&:checked': {
          backgroundColor: '$red500',
          borderColor: '$red500',
        },
      },
      grey: {
        '&:checked': {
          backgroundColor: '$grey600',
          borderColor: '$grey600',
        },
      },
    },
  },
})

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id'> {
  css?: CSS
  label: React.ReactNode
  name: string
  variant?: 'primary' | 'grey'
  options?: RegisterOptions
  reversed?: boolean
}

const FormCheckbox = ({
  css,
  label,
  name,
  options,
  variant = 'primary',
  reversed = false,
  ...props
}: Props) => {
  const { register } = useFormContext()

  return (
    <Label reversed={reversed} css={css} htmlFor={name}>
      <CheckMarkContainer>
        <Checkbox
          id={name}
          type="checkbox"
          variant={variant}
          {...register(name, options)}
          {...props}
        />
        <Aura>
          <Icon.Check size={24 * CHECK_MARK_RATIO} />
        </Aura>
      </CheckMarkContainer>
      {label}
    </Label>
  )
}

export default FormCheckbox
